import { SaveOutlined } from "@mui/icons-material";
import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Input,
  InputLabel,
  TextField,
} from "@mui/material";
import { useRef, useState } from "react";
import { Space } from "../../../components/Space";
import { isBridgeAdmin } from "../../../Config";
import { FetchWithAuth } from "digimaker-ui/util";
import { FileUpload } from "digimaker-ui";
import { getWidget } from "dmeditor";
import { loadWidgetBlocks } from "../../../store/widgetBlocks";

export const SaveBlock = (props: { blockData: any }) => {
  const [shown, setShown] = useState(false);

  const { blockData } = props;

  const hasAccess = isBridgeAdmin();

  const [name, setName] = useState("");
  const [image, setImage] = useState("");

  const uploaded = (data) => {
    setImage(data.nameUploaded);
  };

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState("");

  const submit = () => {
    if (name && image) {
      FetchWithAuth("content/create/widget_block/1", {
        method: "POST",
        body: JSON.stringify({
          name: name,
          image: image,
          widget: blockData.type,
          data: blockData,
        }),
      }).then((data) => {
        if (data.error === false) {
          loadWidgetBlocks();
          window.alert("Saved.");
          setShown(false);
        } else {
          setError(data.message);
        }
      });
    }
  };

  if (!hasAccess) {
    return <></>;
  }

  return (
    <>
      <Button
        variant="text"
        size="small"
        color="warning"
        onClick={() => {
          setShown(true);
        }}
      >
        <SaveOutlined /> Lagre
      </Button>
      <Dialog open={shown} fullWidth>
        <DialogTitle>Lagre widget blokk</DialogTitle>
        <DialogContent>
          <div>{getWidget(blockData.type).name}</div>
          {error && <Alert>{error}</Alert>}
          <Space />
          <div>
            <InputLabel>Navn:</InputLabel>
            <TextField
              fullWidth
              size="small"
              onChange={(e) => setName(e.target.value)}
            />
          </div>

          <Space />

          <div>
            <InputLabel>Blokk bilder:</InputLabel>
            <FileUpload
              service="content"
              value=""
              name="coverimage"
              format="image/*"
              onSuccess={uploaded}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="warning" onClick={submit}>
            Bekreft
          </Button>
          <Button variant="text" onClick={() => setShown(false)}>
            Kansellere
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};
