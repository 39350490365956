import {
  dmeConfig,
  initLanguage,
  registerDefaultWidgets,
  registerWidgetStyle,
  registerWidgetStyleOption,
  registerWidgetVariant,
  setDMEditorConfig,
} from "dmeditor";

import registerClubDropdown from "./widgets/club-dropdown";
import registerCarousel from "./widgets/carousel";
import registerTopNews from "./widgets/top-news";
import registerClubMembers from "./widgets/club-members-countdown";
import registerLinks from "./widgets/links";
import registerNewsList from "./widgets/news-list";
import registerResult from "./widgets/result";
import registerClubInfo from "./widgets/club-info";
import registerKretsInfo from "./widgets/krets-info";
import registerMemberList from "./widgets/memberlist";
import registerArticleDetail from "./widgets/article-detail";
import registerAnnualMeeting from "./widgets/annual-meeting";
import registerCalenderWidget from "./widgets/calendar-widget";
import registerBridgeSpill from "./widgets/bridge-spill";

import { nanoid } from "nanoid";
import { imagePath } from "./widgets/util";
import { defaultStyles } from "./defaultStyle";
import { ContentViewRender } from "./content-view/ContentViewRender";

export const dmeditorInit = (params: { extraConfig?: any }) => {
  initLanguage("nor-NO");
  registerDefaultWidgets();

  registerTopNews();
  registerCarousel();
  registerClubMembers();
  registerClubDropdown();
  registerLinks();
  registerNewsList();
  registerResult();
  registerClubInfo();
  registerKretsInfo();
  registerMemberList();
  registerArticleDetail();
  registerAnnualMeeting();
  registerCalenderWidget();
  registerBridgeSpill();

  registerWidgetStyleOption("text", [
    {
      name: "Article body",
      identifier: "article-body",
      cssStyle: `
        max-width: 960px;
        margin: auto;
    `,
    },
  ]);

  registerWidgetVariant({
    widget: "heading",
    identifier: "title",
    name: "Title",
    category: "basic",
    enabledSettings: [".value"],
    getDefaultData: () => {
      return {
        id: nanoid(),
        type: "heading:title",
        style: { type: "bridge-title" },
        data: { value: "Text", level: 2, settings: { level: 2 } },
      };
    },
  });

  const bridgeTitle = `
    h2{
      color: #000;
      background: transparent;
      font-size: 2.2rem;
      font-family: Cambria;
      font-weight: bold;
      padding: 50px 0 30px;
      margin: 0px;
      text-transform: uppercase;
    }    
  
    h2::after{
      content: ' ';
      display: block;
      width: 4.69rem;
      height: 5px;
      margin: 15px 0 0;
      background: var(--waring-color);
    }
    
  `;

  const bridgeTitleNoPadding = `
    h2{
      color: #000;
      background: transparent;
      font-size: 2.2rem;
      font-family: Cambria;
      font-weight: bold;
      margin: 0px;
      text-transform: uppercase;
    }    
  
    h2::after{
      content: ' ';
      display: block;
      width: 4.69rem;
      height: 5px;
      margin: 15px 0 0;
      background: var(--waring-color);
    }
    
  `;

  const bridgeHeadingStyle = {
    name: "Type",
    identifier: "type",
    options: [
      {
        name: "Underline",
        identifier: "bridge-title-no-space",
        cssStyle: `
      ${bridgeTitleNoPadding}
  `,
      },
      {
        name: "Underline 2",
        identifier: "bridge-title",
        cssStyle: `
      ${bridgeTitle}
  `,
      },
      {
        name: "Bridge center",
        identifier: "bridge-title-center",
        cssStyle: `
      ${bridgeTitle}
      h2{
        text-align: center;
      }
  
      h2:after{
        margin-left:auto;
        margin-right: auto;
      }
  `,
      },
      {
        name: "Strip",
        identifier: "bridge-title-strip",
        cssClasses: { h: "title-with-strip font-Cambria-blod" },
        cssStyle: `       
          
        `,
      },
      {
        name: "Margin space",
        identifier: "text",
        cssStyle: `
             h1, h2, h3 {
                margin-top: 0.67em;
                margin-bottom: 0.67em;
            }
        `,
      },
    ],
  };

  registerWidgetStyle("heading:title", bridgeHeadingStyle);
  registerWidgetStyle("heading", bridgeHeadingStyle);

  ["heading:title", "heading", "bridge-top-news"].forEach((widget) => {
    registerWidgetStyle(widget, {
      name: "Background",
      identifier: "background",
      options: [
        {
          name: "gray",
          identifier: "gray",
          cssStyle: `
            & > div{
              background-color: var(--bg-color);
            }
          `,
        },
      ],
    });
  });

  [
    "heading:title",
    "heading",
    "bridge-top-news",
    "bridge-club-dropdown",
  ].forEach((widget) => {
    registerWidgetStyle(widget, {
      name: "Width",
      identifier: "width",
      options: [
        {
          name: "Full",
          identifier: "fullwidth",
          cssStyle: `
          & > *{
            width: auto;
            margin-left: calc( ( var(--dme-main-width) - var(--dme-container-width) ) / 2 );
            margin-right: calc( ( var(--dme-main-width) - var(--dme-container-width) ) / 2 );            
            padding-left:calc( ( var(--dme-container-width) - var(--dme-main-width) ) / 2 );
            padding-right:calc( ( var(--dme-container-width) - var(--dme-main-width) ) / 2 );                    
          }
          `,
        },
      ],
    });
  });

  registerWidgetStyle("list", {
    name: "Background",
    identifier: "background",
    options: [
      {
        name: "Gray",
        identifier: "gray",
        cssStyle: `
        background-color: var(--bg-color)
    `,
      },
      {
        name: "White",
        identifier: "white",
        cssStyle: `
        background-color: #ffffff
    `,
      },
    ],
  });

  registerWidgetStyle("list", {
    name: "Width",
    identifier: "width",
    options: [
      {
        name: "Full",
        identifier: "full",
        cssStyle: `
        width: auto;
        margin-left: calc( ( 100% - var(--dme-container-width) ) /2 );
        margin-right: calc( ( 100% - var(--dme-container-width) ) /2 );
    `,
      },
      {
        name: "Background full",
        identifier: "background-full",
        cssStyle: `        
        margin-left: calc( ( 100% - var(--dme-container-width) )/2 );
        margin-right: calc( ( 100% - var(--dme-container-width) )/2 );
        padding-left: calc( ( var(--dme-container-width) - 100% )/2 );
        padding-right: calc( ( var(--dme-container-width) - 100% )/2 );
        
    `,
      },
    ],
  });

  registerWidgetStyle("list", {
    name: "Padding up down",
    identifier: "padding-up-down",
    options: [
      {
        name: "Small",
        identifier: "small",
        cssStyle: `
        padding-top: 40px;
        padding-bottom: 40px;
    `,
      },
    ],
  });

  registerWidgetStyleOption("tabs", [
    {
      name: "Underline",
      identifier: "underline",
      cssStyle: `
        
        .dme-w-nav-item{
          border:none;
          padding: 5px 10px;
          background: none;     
          border-bottom: 3px solid rgba(0,0,0,0);     
          color:rgb(50, 111, 75);          
        }

        .dme-w-nav-item:hover{
          color: var(--tab-hover-color);
        }

        .dme-w-nav-item.dme-w-active{
          border-bottom-color: var(--waring-color);
          font-weight: bold;
        }

        div[role='tabpanel']{
          padding: 10px;
        }
      `,
    },
  ]);

  const getColorIcon = (color: string) => {
    return (
      "data:image/svg+xml,%3Csvg%20width%3D%2260%22%20height%3D%2260%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%0A%20%3Cg%3E%0A%20%20%3Cellipse%20stroke-width%3D%220%22%20ry%3D%2225.49977%22%20rx%3D%2225.49977%22%20id%3D%22svg_1%22%20cy%3D%2229.75%22%20cx%3D%2229.75001%22%20stroke%3D%22%23000%22%20fill%3D%22%23" +
      color +
      "%22%2F%3E%0A%20%3C%2Fg%3E%0A%3C%2Fsvg%3E"
    );
  };

  registerWidgetStyleOption("button", [
    {
      name: "Primary",
      identifier: "primary",
      icon: "/images/dme-icons/green.png",
      cssClasses: { button: "btn btn-success" },
      cssStyle: `        
      `,
    },
    {
      name: "Secondary",
      identifier: "secondary",
      icon: "/images/dme-icons/orange.png",
      cssClasses: { button: "btn btn-warning" },
      cssStyle: `     
        background: var(--bs-btn-hover-bg)
        `,
    },
    {
      name: "Light",
      identifier: "light",
      icon: "/images/dme-icons/light.png",
      cssClasses: { button: "btn btn-light" },
      cssStyle: "",
    },
    {
      name: "Link",
      identifier: "link",
      icon: "/images/dme-icons/light.png",
      cssClasses: { button: "btn btn-link" },
      cssStyle: "",
    },
  ]);

  const buttonColors = {
    identifier: "color",
    name: "Color",
    display: "dropdown",
    options: [
      {
        name: "Primary",
        identifier: "primary",
        icon: "/images/dme-icons/green.png",
        cssClasses: { button: "btn btn-success" },
        cssStyle: `        
        `,
      },
      {
        name: "Secondary",
        identifier: "secondary",
        icon: "/images/dme-icons/orange.png",
        cssClasses: { button: "btn btn-warning" },
        cssStyle: `     
          background: var(--bs-btn-hover-bg)
          `,
      },
      {
        name: "Light",
        identifier: "light",
        icon: "/images/dme-icons/light.png",
        cssClasses: { button: "btn btn-light" },
        cssStyle: "",
      },
      {
        name: "White text only",
        identifier: "white-text",
        icon: "/images/dme-icons/light.png",
        cssClasses: { button: "btn" },
        cssStyle: `     
          .btn{
                  --bs-btn-color: white;
                  --bs-btn-hover-color: white;
          }
          `,
      },
      {
        name: "Link",
        identifier: "link",
        icon: "/images/dme-icons/light.png",
        cssClasses: { button: "btn btn-link" },
        cssStyle: "",
      },
      {
        name: "Gray",
        identifier: "gray",
        icon: getColorIcon("6c757d"),
        cssClasses: { button: "btn" },
        cssStyle: `
        .btn{
              --bs-btn-bg: #6c757d;
              --bs-btn-hover-bg: #5a6268;
              --bs-btn-color: white;
              --bs-btn-hover-color: white;
        }
      `,
      },
      {
        name: "Dark",
        identifier: "dark",
        icon: getColorIcon("000000"),
        cssClasses: { button: "btn btn-dark" },
        cssStyle: `     
      `,
      },
      {
        name: "Blue",
        identifier: "blue",
        icon: getColorIcon("192a87"),
        cssClasses: { button: "btn" },
        cssStyle: `
      .btn{
             --bs-btn-bg: #192a87;
             --bs-btn-hover-bg: #122592;
             --bs-btn-color: white;
             --bs-btn-hover-color: white;
             color: white;
      }
      `,
      },
      {
        name: "Light blue",
        identifier: "light-blue",
        icon: getColorIcon("007fff"),
        cssClasses: { button: "btn" },
        cssStyle: `
      .btn{
             --bs-btn-bg: #007bff;
             --bs-btn-hover-bg: #0069d9;
             --bs-btn-color: white;
             --bs-btn-hover-color: white;
      }
      `,
      },
      {
        name: "Brown",
        identifier: "brown",
        icon: getColorIcon("875419"),
        cssClasses: { button: "btn" },
        cssStyle: `
      .btn{
             --bs-btn-bg: #875419;
             --bs-btn-hover-bg: #875419;
             --bs-btn-color: white;
             --bs-btn-hover-color: white;
      }
      `,
      },
      {
        name: "Grass green",
        identifier: "grass-green",
        icon: getColorIcon("738719"),
        cssClasses: { button: "btn" },
        cssStyle: `
      .btn{
             --bs-btn-bg: #738719;
             --bs-btn-hover-bg: #738719;
             --bs-btn-color: white;
             --bs-btn-hover-color: white;
      }
      `,
      },
      {
        name: "Purple",
        identifier: "purple",
        icon: getColorIcon("3E1987"),
        cssClasses: { button: "btn" },
        cssStyle: `
      .btn{
             --bs-btn-bg: #3E1987;
             --bs-btn-hover-bg: #3E1987;
             --bs-btn-color: white;
             --bs-btn-hover-color: white;
      }
      `,
      },
      {
        name: "Light purple",
        icon: getColorIcon("751987"),
        identifier: "light-purple",
        cssClasses: { button: "btn" },
        cssStyle: `
      .btn{
             --bs-btn-bg: #751987;
             --bs-btn-hover-bg: #751987;
             --bs-btn-color: white;
             --bs-btn-hover-color: white;
      }
      `,
      },
      {
        name: "Red",
        identifier: "red",
        icon: getColorIcon("d00d00"),
        cssClasses: { button: "btn" },
        cssStyle: `
      .btn{
             --bs-btn-bg: #d00d00;
             --bs-btn-hover-bg: #d00d00;
             --bs-btn-color: white;
             --bs-btn-hover-color: white;
      }
      `,
      },
      {
        name: "Deep pink",
        identifier: "deep-pink",
        icon: getColorIcon("bb0054"),
        cssClasses: { button: "btn" },
        cssStyle: `
      .btn{
             --bs-btn-bg: #bb0054;
             --bs-btn-hover-bg: #bb0054;
             --bs-btn-color: white;
             --bs-btn-hover-color: white;
      }
      `,
      },
      {
        name: "Gradient red",
        identifier: "gradient-red",
        icon: "/images/dme-icons/gradient_red.png",
        cssStyle: `

        a{
          padding: 8px 20px;
          border-radius: 4px;
          color: white;
          text-align:center;

          background-image: linear-gradient(to right, #FF512F 0%, #DD2476  51%, #FF512F  100%);
          
          transition: 0.5s;
          background-size: 200% auto;
          color: white;                      
        a:hover {
             background-position: right center;
             color: #fff;
           }
      `,
      },
      // box-shadow: 0 0 20px #eee;

      //     }
    ],
  };

  registerWidgetStyle("button", buttonColors as any);
  registerWidgetStyle("popup", buttonColors as any);

  registerWidgetStyle("button", {
    identifier: "icon",
    name: "Icon",
    display: "dropdown",
    options: [
      {
        name: "Go to",
        identifier: "goto",
        cssClasses: { "after-icon": "button-after-space bi bi-arrow-right" },
        cssStyle: `     
          
        `,
      },
      {
        name: "Right",
        identifier: "right",
        cssClasses: { "after-icon": "button-after-space bi bi-chevron-right" },
        cssStyle: `     
          
        `,
      },
      {
        name: "Download",
        identifier: "download",
        cssClasses: { "after-icon": "button-after-space bi bi-download" },
        cssStyle: `     
          
        `,
      },
      {
        name: "More",
        identifier: "more",
        cssClasses: {
          "after-icon": "button-after-space bi bi-chevron-double-right",
        },
        cssStyle: `     
        `,
      },
      {
        name: "Down",
        identifier: "down",
        cssClasses: {
          "after-icon": "button-after-space bi bi-chevron-down",
        },
        cssStyle: `     
        `,
      },
      {
        name: "External link",
        identifier: "external-link",
        cssClasses: {
          "after-icon": "button-after-space bi bi-box-arrow-up-right",
        },
        cssStyle: `     
        `,
      },
      {
        name: "Back (Icon before)",
        identifier: "back",
        cssClasses: { "before-icon": "button-before-space bi bi-arrow-left" },
        cssStyle: `     
          
        `,
      },
    ],
  });

  registerWidgetStyleOption("collapsable-text", [
    {
      name: "Default",
      identifier: "bridge",
      cssClasses: {
        button: "btn btn-success",
      },
      cssStyle: "",
    },
  ]);

  ["hero-text", "bridge-club-members-countdown"].forEach((widget) =>
    registerWidgetStyleOption(widget, [
      {
        name: "Frontpage block space",
        identifier: "frontpage-row-space",
        cssStyle: `
          margin-top: 50px;
        `,
      },
    ])
  );

  for (const widget of Object.keys(defaultStyles)) {
    registerWidgetStyleOption(widget, [
      {
        identifier: "_default",
        name: "Default",
        ...defaultStyles[widget],
      },
    ]);
  }

  registerWidgetStyleOption("carousel", [
    {
      identifier: "default",
      name: "Default",
      ...defaultStyles["carousel"],
    },
  ]);

  setDMEditorConfig({
    general: {
      imagePath: (path: string, size?: string) => {
        return imagePath(path, size === "thumbnail");
      },
      projectStyles: {
        default: `
        --project-main-color: blue;
        --project-main-bg-color: #e0e0ff;
      
        --dme-container-width: 100vw;
        --dme-main-width: 1200px;

        .preview-dmeditor.contenttype-site &{
          --dme-container-width: clamp( var(--preview-container-min-width), calc(90vw * 0.75 - 10px),  90vw);
          --dme-main-width: calc( var(--dme-container-width) - var(--preview-container-gap) );
          width: var(--dme-main-width);
          margin: auto;

          .dme-blocktype-hero-text{
            --dme-container-width: clamp(var(--preview-container-min-width), calc(90vw * 0.75 - 10px),  90vw);
          }

        }

        .card-heart, .card-diamond{
          color: #de0007;
        }

        h4{
          font-size: 1.25rem;
        }

        h5{
          font-size: 1rem;
        }

        strong, b{
          font-weight: bold;
        }

        &.dme-viewmode-mobile, &.dme-viewmode-tablet{
            --dme-main-width: 100vw;
        }

        .btn{
          text-align:center;
        }

        .dme-block > .btn{
          display:block;
        }

        .carousel-item{
          display:block;
        }

        // .dme-blocktype-hero-text{
        //   --dme-container-width: clamp( 100px, 100vw, 1600px );
        // }

        .dme-blocktype-hero-text > .dme-w-list{
          padding-right: 50px;
        }

        p, .dme-blocktype-text li{
          line-height: 1.5;
        }

        .dme-blocktype-accordion .MuiAccordionSummary-content{
          font-size: 1.1rem;
        }

        .button-after-space{
          margin-left: 4px
        }

        .button-before-space{
          margin-right: 4px
        }
          
        .dme-blocktype-image .dme-w-description{
          color: #444;
          font-style: italic;
          margin-top: 5px;
          font-size: 0.95rem;
        }

        &.dme-viewmode-mobile
        {          
            .dme-w-hero + .dme-w-list p{
                padding: 0px 10px;
            }

            .dme-block-depth-1.dme-blocktype-text, .dme-block-depth-1.dme-blocktype-heading, .dme-block-depth-1.dme-blocktype-table {
              margin-left: 10px;
              margin-right: 10px;
            }

            .dme-block-depth-1.dme-blockvariant-title{
              margin-left:0px;
              margin-right:0px;
            }

            .dme-blocktype-hero-text > .dme-w-list{
              padding-right: 0px;
            }

            .dme-blocktype-table{
              overflow-x: auto;
            }

            .table-memberlist{
              overflow-x: auto;
              display: inline-block;
            }

            .dme-blockvariant-title h2{
                font-size: 1.5rem;
            }
                .dme-blockvariant-title h2:not(.title-with-strip){
                    font-size: 1.25rem;
                    padding: 10px 20px;
                    background: #F0F0F0;
                    text-align: left;
                    margin: 0;
                    width: 100%;

                    &::after{
                        content: "";
                        display: block;
                        width: 2px;
                        height: 1.25rem;
                        margin: -24px 0 0 -10px;
                        background: var(--waring-color);
                    }            
                }

          .w-container{
            width:100% !important;
            margin: auto;
          }
        }

        [contenteditable]:focus {
          outline: 0px solid transparent;
      }
      
        --waring-color:#FDA129;
        --tab-hover-color: var(--waring-color);
        --font-Cambria:Cambria;

        /* set below to global css */
        
        --bg-color:#F0F0F0;
        --bs-link-color: #326f4b !important;
        --bs-link-hover-color: #128843 !important;

        --bs-link-color-rgb: 50,111,75;
        --bs-link-hover-color-rgb: 18, 136, 67;

        --first-level-title-font-size:2.2rem;
        --secondary-title-font-size:1.8rem;
        --three-level-title-font-size:1.5rem; 
        font-size: 16px;
  
  
        .w-container{
        //   min-width: 960px;
        //   width:62.5vw;
        //   max-width: 1600px;
        //   margin: auto;
        }              

        .btn-success{
          --bs-btn-color: #fff;
          --bs-btn-bg: #198754;
          --bs-btn-border-color: #198754;
          --bs-btn-hover-color: #fff;
          --bs-btn-hover-bg: #157347;
          --bs-btn-hover-border-color: #146c43;
          --bs-btn-focus-shadow-rgb: 60,153,110;
          --bs-btn-active-color: #fff;
          --bs-btn-active-bg: #146c43;
          --bs-btn-active-border-color: #13653f;
          --bs-btn-active-shadow: inset 0 3px 5px rgba(0,0,0,.125);
          --bs-btn-disabled-color: #fff;
          --bs-btn-disabled-bg: #198754;
          --bs-btn-disabled-border-color: #198754;
        }

        .btn-warning{
          --bs-btn-color: #fff!important;
          --bs-btn-bg: var(--waring-color)!important;
          --bs-btn-border-color: var(--waring-color)!important;
          --bs-btn-hover-color: #fff!important;
          --bs-btn-hover-bg: #fdab3f!important;
          --bs-btn-hover-border-color: #fdab3f!important;
          --bs-btn-focus-shadow-rgb: 217,164,6!important;
          --bs-btn-active-color: #fff!important;
          --bs-btn-active-bg: #fdab3f!important;
          --bs-btn-active-border-color: #fdab3f!important;
          --bs-btn-active-shadow: inset 0 3px 5pxrgba(0, 0, 0, 0.125)!important;
          --bs-btn-disabled-color: #fff!important;
          --bs-btn-disabled-bg: #fdab3f!important;
          --bs-btn-disabled-border-color: #fdab3f!important;
        }

        .bg-blue-500{
          background: #198754;
        }                 
        
        .tabs-more > li:last-child > button{
          padding: 0px;
        }

        .tabs-more > li:last-child > button a{
          border-top:none !important;
          border-left:none !important;
          border-right:none !important;
          border-bottom:1px solid rgba(0,0,0,0) !important;
        }

        `,
      },
      deviceWidth: {
        mobile: 560,
        tablet: 960,
        pc: 8000,
      },
    },
    editor: {
      defaultTheme: "default",
      favouriteWidgets: [],
      zIndex: 500,
      colors: {
        text: [
          { color: "#326f4b", name: "Green" },
          { color: "#A61C00", name: "Dark red" },
          { color: "#FF0000", name: "Red" },
          { color: "#1155CC", name: "Blue" },
          { color: "#9900FF", name: "Dark purple" },
          { color: "#FF00FF", name: "Light purple" },
          { color: "#ffffff", name: "White" },
        ],
        border: [
          { color: "#000000" },
          { color: "#333333" },
          { color: "#666666" },
          { color: "#999999" },
          { color: "#cccccc" },
          { color: "#ffffff" },
        ],
        background: [
          { color: "#F3F3F3", name: "Light white" },
          { color: "#D9EAD3", name: "Light green" },
          { color: "#F4CCCC", name: "Light red" },
          { color: "#FFF2CC", name: "Light yellow" },
          { color: "#198754", name: "Green" },
          { color: "#A61C00", name: "Dark red" },
          { color: "#434343", name: "Dark gray" },
        ],
      },
      characters: [
        "♠️",
        "♥️",
        "♣️",
        "♦️",
        "😃",
        "🙂",
        "😁",
        "😆",
        "😅",
        "😇",
        "👍",
        "🤝",
        "👌",
        "🎁",
        "🇳🇴",
      ],
      enableEditControl: false,
      ui: {
        // "bg-editarea": "#646c71",
      },
      categories: [{ identifier: "bridge", name: "Bridge" }],
      defaultStyle: {
        tabs: { _: "_default" },
        button: { color: "primary" },
        table: { _: "_default" },
        heading: { type: "text" },
        accordion: { _: "_default" },
        menu: { _: "_default" },
        gallery: { _: "_default" },
        popup: { color: "primary" },
        carousel: { _: "default" },
        form: { _: "_default" },
        "form-field": { _: "_default" },
        "collapsable-text": { _: "bridge" },
      },
    },
    ...(params.extraConfig ? params.extraConfig : {}),
    widgets: {
      "content-view": {
        render: ContentViewRender,
      },
      form: {
        submit: async () => {
          return {
            success: false,
            errorMessage:
              "Form handling is not implemented. Contact administrator to support server side.",
          };
        },
      },
    },
  });
};
