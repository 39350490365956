import { BlockListRender, BlockRender, DMEData } from "dmeditor";
import styled from "@emotion/styled";
import { DME } from "dmeditor";
import { EntityBridgeCarousel } from "./entity";

const Container = styled.div<{ mobileHeight?: number }>`
  .dme-viewmode-mobile & {
    overflow: hidden;
    ${(props) =>
      props.mobileHeight
        ? { "--bridge-carousel-height": props.mobileHeight + "px" }
        : { "--bridge-carousel-height": "70vh" }}
  }
`;

const BridgeCarousel = (props: DME.WidgetRenderProps<EntityBridgeCarousel>) => {
  const {
    blockNode: { children, data },
    mode,
    path,
    styleClasses,
  } = props;

  return children ? (
    <>
      <Container
        className={
          styleClasses["bridge-carousel"] || "bridge-carousel-carousel"
        }
        mobileHeight={data.settings?.heightMobile}
      >
        {children[0] && (
          <BlockRender
            mode={mode}
            data={children[0] as any}
            path={[...path, 0]}
          />
        )}
      </Container>
      <div className="bridge-carousel-text">
        {children[1] && (
          <BlockRender
            mode={mode}
            data={children[1] as any}
            path={[...path, 1]}
          />
        )}
      </div>
      <div className="bridge-carousel-buttons">
        {children[2] && (
          <BlockRender
            mode={mode}
            data={children[2] as any}
            path={[...path, 2]}
          />
        )}
      </div>
    </>
  ) : (
    <></>
  );
};

export default BridgeCarousel;
