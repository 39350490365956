import { FetchWithAuth } from "digimaker-ui/util";

let widgetBlocks: Array<any> = [];

export const getWidgetBlocks = () => {
  return widgetBlocks;
};

export const loadWidgetBlocks = async () => {
  try {
    const widgetBlocksResp = await FetchWithAuth(
      "content/list/widget_block?parent=1"
    );
    if (widgetBlocksResp.error === false) {
      widgetBlocks = await widgetBlocksResp.data.list;
    }
  } catch (ex) {}
};
