import { registerIcon, setDMEditorCallback } from "dmeditor";
import { BrowseImage } from "./components/dmeditor-callbacks/BrowseImage";
import { BrowseLink } from "./components/dmeditor-callbacks/BrowseLink";

import { dmeditorInit } from "shared/src/DMEditorInit";
import { nanoid } from "nanoid";
import "shared/src/public-common.css";
import {
  fetchInClient,
  SelectContent,
} from "./components/dmeditor-callbacks/SelectContent";
import { SaveBlock } from "./pages/content/dmeditor/SaveBlock";

setDMEditorCallback({
  browseImage: BrowseImage,
  browseLink: BrowseLink,
});

registerIcon({
  name: "bridge",
  component: () => <img height="20px" alt="" src="/logo.png" />,
});

dmeditorInit({
  extraConfig: {
    dataSource: { edit: SelectContent, fetchInClient: fetchInClient },
    plugins: { blockSettingActions: [SaveBlock] },
  },
});
