/**
 * 
 * 
 * Use in DM Editor:
 * 
 import { defaultStyles } from './defaultStyles';

 for (const widget of Object.keys(defaultStyles)) {
    registerWidgetStyleOption(widget, [defaultStyles[widget]]);
 }
 */

 export const defaultStyles = {    
    accordion:{
        identifier:'_default', 
        name:'Default',
        cssStyle:`
            & > .dme-w-container{
                border-bottom: 1px solid rgb(204, 204, 204);
                border-left: 1px solid rgb(204, 204, 204);
                border-right: 1px solid rgb(204, 204, 204);   
                border-radius: 5px;             

            .dme-w-open > .dme-w-summary{
                // background: #f0f0f0;
            }

            .dme-w-summary{
                padding: 10px;
                font-weight: bold;
                border-top: 1px solid rgb(204, 204, 204);
            }

            .dme-w-summary:hover{
                // background: #f0f0f0;
            }

            .dme-w-body{
                padding: 20px;
            }
            }            
        `
    },
    tabs:{
            identifier:'_default', 
            name:'Default',
            cssStyle:`
            div[role='tablist']{
                border-bottom: 1px solid #cccccc;
                padding-left: 10px;

                 .dme-w-nav-item{
                  padding: 10px 15px;
                  border: none;
                  cursor: pointer;
                  background: white;
                  font-size: 1rem;
                  color:rgb(50, 111, 75);
                }

                .dme-w-nav-item:hover{
                    color: var(--tab-hover-color);
                }

                .dme-w-active{
                    font-weight: bold;
                }

                .dme-w-nav-item.dme-w-active{
                    border-top: 1px solid #cccccc;
                    border-left: 1px solid #cccccc;
                    border-right: 1px solid #cccccc;
                    border-bottom: 1px solid white;
                    margin-bottom: -1px;
                    border-radius: 4px 4px 0px 0px;
                }

            }            


            div[role='tabpanel']{
                padding: 10px 15px;
            }

            `
        },
    table:{
        identifier: '_default',
        name: 'Default',
        cssStyle:`
            .dme-viewmode-mobile & {
                max-width: 100%;
                overflow: auto;
            }
        `
    },    
    'menu':{
        cssClasses:{
            'container':'dme-w-container',
            'menuitem':'dme-w-menuitem',
            'current':'dme-w-current',                          
        },
        cssStyle:`
                .dme-w-container{
                    padding: 10px;
                }
                
                .dme-w-menuitem{
                    padding: 5px;
                }

                .dme-w-current{
                    font-weight:bold;
                }
            `
    },
    'carousel':{
    cssStyle:`           
       .dme-w-arrow-button{
            margin: 10px;
            padding: 10px;
            border-radius: 50%;
            color: block;
            background: white;
            opacity: 0.25;
            
            & > svg{
               font-size: 28px;
            }
  
            &:hover{
               opacity: 0.5;
               margin-top: 11px;
            }
     `
    },
    gallery:{
        cssStyle:`           
            .dme-w-pagination-container{
                margin-top: 20px;
            }

            .dme-w-pagination-item{
              padding: 5px 10px;
              border: 1px solid #cccccc;
              margin-left: 5px;
              display:inline-block;
             margin-bottom: 10px;
            }

            .dme-gallery-img-wrapper:hover{
                opacity: 0.85;
            }

            .dme-w-indicator{
                font-size: 14px;
                color: white;
                text-shadow: 1px 1px 1px #333333;
            }

            .dme-w-pagination-item-current{
              font-weight: bold;
            }            
        `
    },
    form:{
        cssClasses:{
            submit:'btn btn-sm btn-success',
            reset:'btn btn-sm',
            'error-message':'alert alert-warning',
            'success-message':'alert alert-success',            
        },
        cssStyle:`           
       
       
        ` 
    },
    'form-field':{
        cssClasses:{
            'input-text':'form-control',
            'input-textarea':'form-control',
            'input-select':'form-control',
            'input-file':'form-control',            
        },
        cssStyle:`

        ` 
    }
}